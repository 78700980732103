video._2WNzJ {
  -o-object-position: center;
     object-position: center;
  border-radius: 4px;
  min-height: 0;
  min-width: 0;
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

._34YL4 {
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0);
}

._1y2fW {
  border: 2px solid #28994e;
}

._30KSQ {
  background: #c4c4c4;
  width: 100%;
  height: 100%;
}

._2LCHl {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 6px 8px 6px 8px;
  font-weight: 500;
  font-size: 12px;
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr -webkit-min-content -webkit-min-content;
  grid-template-columns: auto 1fr min-content min-content;
  justify-content: space-evenly;
  align-items: center;
}

._2LCHl ._55u8f {
  white-space: nowrap;
  justify-self: start;
}

._2LCHl ._213aF {
  justify-self: center;
}

._2LCHl ._te685 {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._1FIqv {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

._3LzZj {
  color: #981010;
}

._2kKks {
}

._3w3nM {
  display: inline-block;
  margin: 4px;
  white-space: nowrap;
  height: 43px;
}

._hRq5k {
  display: inline-block;
  border-radius: 44px;
  border: none;
  background: #2f2f2f;
  padding: 12px 20px 12px 20px;
  cursor: pointer;
  color: white;
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
}

._hRq5k:active {
  border: none;
}

._hRq5k:hover {
  background: #434343;
}

._hRq5k:disabled {
  opacity: 0.5;
  cursor: default;
}

._hRq5k ._1Nxni {
  display: inline-block;
  margin-right: 8px;
}

button._Rt0RH {
  background: #981010;
}

button._Rt0RH:hover {
  background: #b81212;
}

button._3Lz0y {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 12px;
  padding-right: 15px;
  margin-left: 0;
  height: 43px;
  padding-bottom: 11px;
}

button._3Bgvz {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 12px;
  margin-right: 0;
}

._1VLMM {
  display: inline-block;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-right: 8px;
  margin-left: -12px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 20px;
}

._3XXS4 {
  background: #2f2f2f;
  border-radius: 4px;
  margin-bottom: 5px;
}

ul._dKNN7 {
  border: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-inline: 0;
}

ul._dKNN7 li {
  font-size: 14px;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

ul._dKNN7 li:hover {
  background: #434343;
}

ul._dKNN7 li:first-child {
  border: 0;
}

._14898 {
  width: 100%;
  height: 100%;
  position: relative;
}

._17KXW {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

._3QhLc {
  display: block;
  background-color: #5a8bff;
  cursor: pointer;
  padding: 12px 20px;
  border: none;
  color: white;
  font-weight: 700;
  filter: drop-shadow(3px 7px 13px rgba(0, 0, 0, 0.17));
  border-radius: 8px;
  text-transform: uppercase;
}

._3QhLc:active {
  border: none;
}

._3QhLc ._1Nxni {
  display: inline-block;
  margin-right: 12px;
}

._2sm4e {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto -webkit-min-content;
  grid-template-rows: auto min-content;
  min-height: 0;
}

._2VE66 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100%;
  gap: 8px;
  overflow: hidden;
}

._2kKDi {
  grid-column: 1 / 11;
}

._gA0MQ {
  grid-column: 11 / 13;
  display: grid;
  gap: 8px;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  overflow-y: scroll;
}

._1smc2 {
  height: 100px;
  display: grid;
  align-items: center;
  justify-items: center;
}

._2HbZ0 {
  display: grid;
  gap: 8px;
  overflow: hidden;
  height: 100%;
  align-items: center;
  justify-items: center;
}

._2ltyS {
  grid-template-columns: auto;
  grid-template-rows: auto;
}

._2vumQ {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}

._X-1Rk {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

._3rLR0 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

._3qF9s {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

._mDdNR {
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

._2zM8F {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto -webkit-min-content -webkit-min-content;
  grid-template-rows: auto min-content min-content;
  min-height: 0;
  row-gap: 2%;
}

._9PXrI {
  display: grid;
  overflow: hidden;
}

._Tl5ht {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-wrap: wrap; */
  -moz-column-gap: 8px;
       column-gap: 8px;
  justify-content: space-around;
  /* height: 77vh; */
  /* overflow-x: scroll; */
  /* height: 155px; */
  /* scrollbar-width: none;
  -ms-overflow-style: none; */
}

/* .participantsArea::-webkit-scrollbar {
  width: 10;
  height: 10;
} */
._jGXiG {
  flex-shrink: 0;
  width: 95%;
  /* margin-top: 30px; */
  /* height: 50%; */
}
._Q_0VH{
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: right;
  
}
._3XIHm {
  display: inline-block;
  border-radius: 15px;
  border: none;
  background: #2f2f2f;
  /* padding: 12px 20px 12px 20px; */
  width: 85px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-left: 15px;
  cursor: pointer;
  color: white;
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
}
._16Bot {
  width: 85px;
  margin-left: 15px;
}
._3MB5b {
  height: 50px;
  display: grid;
  align-items: center;
  justify-items: center;
}
